










































































import Vue from 'vue';
import { IHomeDailyProgressStep } from '@/scripts/store/modules/home/types';
import HomeStep from '@/views/home/steps/home-step.vue';
import VimeoVideo from '@/views/components/utilities/vimeo-video.vue';
import PrimaryButton from '@/views/components/button/primary-button.vue';
import moment from 'moment';

export enum ROTATION_OPTIONS {
  /* eslint-disable no-unused-vars */
  COMMUNITY = 'COMMUNITY',
  STRATEGIC_BREATHING = 'STRATEGIC_BREATHING',
  GUIDED_IMAGERY = 'GUIDED_IMAGERY',
  MINDFULNESS = 'MINDFULNESS',
  /* eslint-enable no-unused-vars */
}

interface contentOptions {
  id: ROTATION_OPTIONS,
  title: string,
  subtitle?: string,
  description: string,
  time: string,
  vimeoId?: string,
  titleCompleted: string,
  congratulations: string,
}

export default Vue.extend({
  name: 'home-connect-others',
  components: {
    HomeStep,
    VimeoVideo,
    PrimaryButton,
  },
  props: {
    step: {
      type: Object as () => IHomeDailyProgressStep,
      required: true,
    },
  },
  data() {
    return {
      rotationOptions: ROTATION_OPTIONS,
      thumbnailUrl: null,
      vimeoData: null,
      videoData: null,
      displayModal: false,
      contentIndex: 0,
      content: [ {
        id: ROTATION_OPTIONS.COMMUNITY,
        title: this.$t('app.home.recommendations.connect_others.community_forums.title'),
        subtitle: undefined,
        description: this.$t('app.home.recommendations.connect_others.community_forums.description'),
        time: this.$t('app.home.recommendations.connect_others.community_forums.time'),
        vimeoId: undefined,
        titleCompleted: this.$t('app.home.recommendations.connect_others.community_forums.title_completed'),
        congratulations: this.$t('app.home.recommendations.connect_others.community_forums.congratulations'),
      }, {
        id: ROTATION_OPTIONS.STRATEGIC_BREATHING,
        title: this.$t('app.home.recommendations.connect_others.strategic_breathing.title'),
        subtitle: this.$t('app.home.recommendations.connect_others.strategic_breathing.subtitle'),
        description: this.$t('app.home.recommendations.connect_others.strategic_breathing.description'),
        time: this.$t('app.home.recommendations.connect_others.strategic_breathing.time'),
        vimeoId: '181820578',
        titleCompleted: this.$t('app.home.recommendations.connect_others.strategic_breathing.title_completed'),
        congratulations: this.$t('app.home.recommendations.connect_others.strategic_breathing.congratulations'),
      }, {
        id: ROTATION_OPTIONS.GUIDED_IMAGERY,
        title: this.$t('app.home.recommendations.connect_others.guided_imagery.title'),
        subtitle: this.$t('app.home.recommendations.connect_others.guided_imagery.subtitle'),
        description: this.$t('app.home.recommendations.connect_others.guided_imagery.description'),
        time: this.$t('app.home.recommendations.connect_others.guided_imagery.time'),
        vimeoId: '195714424',
        titleCompleted: this.$t('app.home.recommendations.connect_others.guided_imagery.title_completed'),
        congratulations: this.$t('app.home.recommendations.connect_others.guided_imagery.congratulations'),
      }, {
        id: ROTATION_OPTIONS.MINDFULNESS,
        title: this.$t('app.home.recommendations.connect_others.mindfulness.title'),
        subtitle: this.$t('app.home.recommendations.connect_others.mindfulness.subtitle'),
        description: this.$t('app.home.recommendations.connect_others.mindfulness.description'),
        time: this.$t('app.home.recommendations.connect_others.mindfulness.time'),
        vimeoId: '282579859',
        titleCompleted: this.$t('app.home.recommendations.connect_others.mindfulness.title_completed'),
        congratulations: this.$t('app.home.recommendations.connect_others.mindfulness.congratulations'),
      } ] as contentOptions[],
    };
  },
  computed: {
    activeContent(): contentOptions {
      return this.content[this.contentIndex];
    },
  },
  mounted() {
    if (this.step.content) {
      this.contentIndex = this.content.findIndex(rotation => rotation.id.toString() === this.step.content);
    } else {
      this.contentIndex = moment().dayOfYear() % this.content.length;
    }

    if (this.activeContent.vimeoId) {
      this.$store.dispatch('getVideoByVimeoId', this.activeContent.vimeoId).then(video => {
        this.$http.get(`https://vimeo.com/api/oembed.json?url=https://vimeo.com/${video.vimeoId}`)
          .then((res:any) => {
            this.vimeoData = res.data;
            this.videoData = video;
          });
      });
    }
  },
  methods: {
    onStepSelected() {
      if (this.activeContent.id === ROTATION_OPTIONS.COMMUNITY) {
        if (this.step.completed) {
          this.goToProfileComments();
        } else {
          this.goToCommunity();
        }
      } else {
        if (this.step.completed) {
          this.goToVideos();
        } else {
          this.openModal();
        }
      }
    },
    goToCommunity() {
      this.completeDailyStep().finally(() => {
        this.$router.push({ name: 'community-topics' });
      });
    },
    goToProfileComments() {
      this.$router.push({ name: 'community-comments' });
    },
    goToVideos() {
      this.$router.push({ name: 'videos' });
    },
    openModal () {
      this.displayModal = true;
    },
    closeModal () {
      this.completeDailyStep().finally(() => {
        this.displayModal = false;
      });
    },
    onVideoEvent(event:any) {
      this.$store.dispatch('therapy/watchVideoEvent', { videoId: this.activeContent.vimeoId, status: event });
    },
    async completeDailyStep(): Promise<void> {
      if (!this.step.completed) await this.$store.dispatch('home/completeDailyStep', this.step.type);
      return Promise.resolve();
    },
  },
});
